import { I18 } from '../../languages/I18';
import { useTranslate } from '../../i18translate/Hooks';
import { useSession } from '../../utils/Auth';
import { useLazyGetAllProjectsQuery } from '../Home/services/APIMainService';
import CommonLoader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import { CurrentProjectDetails } from '../Home/models/homepageModel';
import { useEffect, useState } from 'react';
import {
  CommonuxSearchbar,
  CommonuxTooltip,
} from '@abb-motion-ux/commonux-web-components-react';
import Button from '../../components/Form/Button';
import LoginModal from '../Home/Components/LoginModal';
import Icon from '../../components/Icon';
import ButtonGroupCommonux from '../../components/ButtonGroup';
import ButtonGroupItemCommonux from '../../components/ButtonGroupItem';
import ProjectGridOrListView from './components/ProjectGridOrListView';
import notificationManager from '../../utils/NotificationManager';
import Cookies from 'js-cookie';
import ProjectTypeSelectionModal from '../Home/Components/ProjectTypeSelectionModal';
import { useDispatch } from 'react-redux';
import { setProjectTypeSelected } from '../Home/store/actions/homeActions';

const Projects = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { session, loading } = useSession();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState<
    CurrentProjectDetails[] | undefined
  >();
  const [allProjects, setAllProjects] = useState<
    CurrentProjectDetails[] | undefined
  >();
  const [filter, setFilter] = useState('');
  const [getAllProjectsData] = useLazyGetAllProjectsQuery();
  const [isListView, setIsListView] = useState(false);
  const [viewProjectTypeSelectionModal, setViewProjectTypeSelectionModal] =
    useState(false);

  const FetchProjectData = () => {
    setIsLoading(true);
    getAllProjectsData(session?.user.access_token)
      .then((response) => {
        if (response.status === 'fulfilled') {
          if (response.data) {
            setAllProjects(response.data?.projects);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          setAllProjects([]);
          notificationManager.error('Unable to fetch all projects');
        }
      })
      .catch(() => {
        setIsLoading(false);
        notificationManager.error('Unable to fetch all projects');
      });
  };

  useEffect(() => {
    if (session?.user.access_token) {
      FetchProjectData();
    }
  }, [session?.user.access_token]);

  useEffect(() => {
    const currentProjects = allProjects;
    const updatedProjects = currentProjects?.filter((x) =>
      x.name.toLowerCase().includes(filter.toLowerCase()),
    );

    setProjects(updatedProjects);
  }, [filter, allProjects]);

  useEffect(() => {
    if (!loading && !session?.user) {
      navigate('/');
    }
  }, [session, loading, navigate]);

  const handleHomeClick = () => {
    navigate('/');
  };
  const onClose = () => {
    setModalOpen(false);
  };

  const handleViewAsChange = (type: string) => {
    setIsListView(type === 'listView');
  };

  const onProjectTypeSelectionModalClose = () => {
    setViewProjectTypeSelectionModal(false);
  };

  const handleProjectOptionClick = (option: number) => {
    setModalOpen(true);
    dispatch(setProjectTypeSelected(option.toString()));
    setViewProjectTypeSelectionModal(false);
  };

  const handleNewProjectClick = () => {
    if (process.env.REACT_APP_PLC_CKNAME) {
      const date = new Date();
      Cookies.set(process.env.REACT_APP_PLC_CKNAME, date.getTime().toString(), {
        expires: 1,
        path: '/',
        domain: '.goselect.motion.abb.com',
      });
    }

    if (session?.user) {
      setViewProjectTypeSelectionModal(true);
    } else {
      setModalOpen(true);
    }
  };

  return (
    <div className="layout-container">
      <div className="projects-header-container">
        <div
          role="button"
          className="home-button"
          data-rf="all-projects-home-button"
          onClick={handleHomeClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              handleHomeClick();
            }
          }}
        >
          <Icon
            className="home-button-icon"
            slot="icon"
            size="small"
            name="left"
            theme="dark"
          />{' '}
          Home
        </div>
        <h3 className="projects-header">Projects</h3>
      </div>
      <div className="projects-search-container">
        <CommonuxSearchbar
          className="projects-search"
          onFieldInput={(event) => {
            setFilter(event.detail.target.value);
          }}
          placeholder="Search projects"
          size="large"
          data-rf="project-search-container"
          data-testid="project-search-container"
        />
        <span>
          <CommonuxTooltip
            alignment="start"
            arrowPosition="center"
            placement="top"
            size="small"
            triggerEvent="hover"
            removeTooltip={
              isLoading || (allProjects && allProjects?.length < 50)
            }
          >
            <Button
              type="primary-red"
              size="large"
              onClick={handleNewProjectClick}
              data-rf="new-project-button"
              disabled={isLoading || (allProjects && allProjects?.length >= 50)}
              slot="tooltip-parent"
              data-testid="new-project-button"
            >
              <Icon slot="icon" size="small" name="plus" theme="light" />
              {t(I18.home_dashboard_new_project_button)}
            </Button>
            <div className="project-tooltip-container" slot="content-section">
              <div className="project-tooltip-header">
                Storage limit exceeds
              </div>
              <div className="project-tooltip-label">
                Please delete an existing
                <br />
                project to create a new one.
              </div>
            </div>
          </CommonuxTooltip>
        </span>
      </div>
      <div
        className="project-view-as-container"
        data-rf="project-view-as-container"
      >
        <ButtonGroupCommonux
          selectedSegmentedControlId={isListView ? 'listView' : 'gridView'}
        >
          <ButtonGroupItemCommonux
            segmentedControlItemId="gridView"
            onSegmentedControlItemClick={() => handleViewAsChange('gridView')}
            data-rf="grid-view-button"
          >
            <Icon slot="icon" size="small" name="matrix" theme="dark" />
          </ButtonGroupItemCommonux>
          <ButtonGroupItemCommonux
            segmentedControlItemId="listView"
            onSegmentedControlItemClick={() => handleViewAsChange('listView')}
            data-rf="list-view-button"
          >
            <Icon slot="icon" size="small" name="list" theme="dark" />
          </ButtonGroupItemCommonux>
        </ButtonGroupCommonux>
      </div>
      {filter && (
        <div className="project-results-label">
          {projects?.length} result(s)
        </div>
      )}
      <div className="all-projects-container" data-rf="all-projects-container">
        {isLoading ? (
          <CommonLoader
            size="xlarge"
            indicatorType={'circular'}
            progressType={'indeterminate'}
            progressValue={50}
            type={'primary-black'}
          />
        ) : (
          <ProjectGridOrListView
            isListView={isListView}
            projects={projects}
            fetchProjectDataAction={FetchProjectData}
            isLoading={isLoading}
          />
        )}
      </div>
      <LoginModal
        modalOpen={modalOpen}
        onClose={onClose}
        projectData={projects}
      />
      <ProjectTypeSelectionModal
        handleProjectOptionClick={handleProjectOptionClick}
        modalOpen={viewProjectTypeSelectionModal}
        onClose={onProjectTypeSelectionModalClose}
      />
    </div>
  );
};

export default Projects;
