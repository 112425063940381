import Icon from '../../../../components/Icon';
import Checkbox from '../../../../components/Form/Checkbox';
import CommonLoader from '../../../../components/Loader';
import Button from '../../../../components/Form/Button';
import {
  DataGrid,
  DataGridRow,
  DataGridCell,
  DataGridHeader,
} from '../../../../components/DataGrid';
import {
  useGetpluscodeByIdQuery,
  usePostPlusCodeDataByIdMutation,
} from '../../services/DrivePluscodeService';
import { useEffect, useState } from 'react';
import {
  setPlusCodeOptionValues,
  updateProductTypeDriveCode,
} from '../../store/WorkspaceReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import { useSession } from '../../../../utils/Auth';
import notificationManager from '../../../../utils/NotificationManager';
import { useNavigate } from 'react-router-dom';
import { deleteSessionStorageValue } from '../../../../utils/GeneralUtils';
import {
  plusCodeOptions,
  PlusCodesObject,
} from '../../models/DrivePlusCodeModel';
import { appInsights } from '../../../../services/AppInsight';

const DriveResults = () => {
  const navigate = useNavigate();
  const currentAppRank = useSelector(
    (state: RootState) => state.workspace.currentAppRank,
  );
  const application = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );
  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );
  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput,
  );

  const { isLoading, isSuccess, data, isUninitialized } =
    useGetpluscodeByIdQuery(application?.id, {
      skip: window.location.search ? false : true,
    });
  const [updateProject, results] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const [updatePlusCode] = usePostPlusCodeDataByIdMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const { session } = useSession();
  const dispatch = useDispatch();

  const [plusCodeValues, setPLusCodeValues] = useState<any>([]);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showAllCheckboxValues, setShowAllCheckboxValues] = useState(false);

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name: 'Drive configurator page',
        pageType: 'Main page',
      });
    } else {
    }
  }, []);

  useEffect(() => {
    if (data && data?.options?.length > 0) {
      const plusCodes = {
        longtypeCode: data?.longTypeCode,
        options: data?.options,
      };

      addCheckboxValuetoOptions(plusCodes);
    } else {
      application?.drivePlusCodeObject?.options &&
        addCheckboxValuetoOptions(application?.drivePlusCodeObject);
    }
  }, [data, currentAppRank]);

  const addCheckboxValuetoOptions = (plusCodes: {
    longtypeCode?: string | undefined;
    options: any;
  }) => {
    if (plusCodes?.longtypeCode && plusCodes?.longtypeCode?.length > 0) {
      const modifiedDataValue =
        plusCodes.options &&
        plusCodes?.options?.map(
          (plusCodeOption: {
            longtypeCode?: string | undefined;
            options: any;
          }) => ({
            ...plusCodeOption,
            checked: false,
          }),
        );

      dispatch(updateProductTypeDriveCode(plusCodes?.longtypeCode));
      dispatch(setPlusCodeOptionValues(plusCodes));
      setPLusCodeValues({
        longtypeCode: plusCodes.longtypeCode,
        options: modifiedDataValue,
      });
    }
  };

  useEffect(() => {
    if (data && data?.options?.length > 0) {
      updateProjectApiCall(data);
      notificationManager.success('Plus codes has been copied to drive(s).');
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (
        window?.location?.search?.length > 0 &&
        window.location?.search?.split('&')[1].slice(6)
      ) {
        navigate('/workspace');
        deleteSessionStorageValue();
      }
    }, 2500);
    //when user clicked and came back without having configuration data.

    //when user is notautorized or when lvdrive page failed
    if (!session?.user?.access_token) {
      deleteSessionStorageValue();
    }
  }, [results.isSuccess, session]);

  const updateProjectApiCall = (plusCodes?: PlusCodesObject) => {
    const dimensionInput =
      application?.dimensionDetails?.selectDriveResult?.dimension
        ?.dimensionInput?.selectionInputs?.[0];

    const updateProjectObject = {
      projectId:
        (projectDetails.projectId || window.sessionStorage.getItem('id')) ?? '',
      projectName:
        (projectDetails.projectName || window.sessionStorage.getItem('name')) ??
        '',
      jsonFileName: application?.id, //appid,
      projectJson: JSON.stringify({
        dimensionSavedData: {
          dimensionInput,
          applicationOutput: {
            supplyDriveLoads:
              application?.dimensionDetails?.selectDriveResult?.dimension
                ?.dimensionResult?.supplyDriveLoads,
          },
          exceptionType:
            application?.dimensionDetails?.selectDriveResult?.exceptionType,
          message: application?.dimensionDetails?.selectDriveResult?.message,
        },
        selectionSavedData: {
          industryName: application?.selectionDetails?.industryName,
          applicationName: application?.selectionDetails?.applicationName,
        },
        efficiencySavedData: {
          motorId:
            application?.pdsEfficiencyDetails?.motorTechnicalData?.motorId ??
            application?.motorEfficiencyDetails?.motorTechnicalData?.motorId ??
            '',
          driveTypeCode:
            application?.pdsEfficiencyDetails?.pdsDriveDetails?.driveTypeCode ??
            application?.driveEfficiencyDetails?.driveTechnicalData
              ?.driveTypeCode ??
            '',
          udpPoints:
            application?.efficiencyUDPLossesdata?.map(
              (item: { data: { speed: any; torque: any } }) => ({
                speed: item?.data?.speed,
                torque: item?.data?.torque,
              }),
            ) ?? [],
          driveFrame: '',
        },
        plusOptions: plusCodes
          ? plusCodes
          : application?.drivePlusCodeObject?.options &&
              application?.drivePlusCodeObject?.options?.length > 0
            ? {
                longtypeCode: application?.drivePlusCodeObject?.longtypeCode,
                options: application?.drivePlusCodeObject?.options,
              }
            : null,
      }),
      transformerId: selectedTransformerDatas?.trafoId,
      applicationId: application?.id,
    };

    updateProject({
      userToken: session ? session?.user?.access_token : undefined,
      projectData: updateProjectObject,
    }).catch((error) => {
      notificationManager.error(
        'Unable to autosave the project due to: ' + error.toString(),
      );
    });
  };

  const allSelectPlusCodeOptions = (e: {
    target: { checked: boolean; id: string };
  }) => {
    const { checked } = e.target;

    const values = plusCodeValues?.options?.map((plusCodeValue: any) => ({
      ...plusCodeValue,
      checked: checked,
    }));

    setPLusCodeValues({
      longtypeCode: plusCodeValues?.longtypeCode,
      options: values,
    });

    plusCodeValues?.options?.length > 0 && checked
      ? setShowDeleteIcon(true)
      : setShowDeleteIcon(false);
    setShowAllCheckboxValues(checked);
  };

  const handleDelete = () => {
    let filteredPlusCodeValues = plusCodeValues?.options?.filter(
      (plusCodeValue: { checked: boolean }) => !plusCodeValue.checked,
    );

    const checkedItemPlusCodes = plusCodeValues?.options
      .filter(
        (plusCodeValue: { checked: boolean }) => plusCodeValue.checked === true,
      )
      .map((plusCodeValue: { plusCode: any }) => plusCodeValue.plusCode)
      .join(' ');
    const longTypeCodeComponents =
      plusCodeValues?.longtypeCode && plusCodeValues?.longtypeCode.split('+');
    const filteredLongTypeCode =
      longTypeCodeComponents &&
      longTypeCodeComponents
        .filter((component: any) => !checkedItemPlusCodes.includes(component))
        .join('+');

    filteredPlusCodeValues = filteredPlusCodeValues.map(
      (item: { checked: any }) => {
        delete item.checked;
        return item;
      },
    );
    if (filteredPlusCodeValues?.length !== plusCodeValues?.options?.length) {
      updatePlusCode({
        applicationId: application.id,
        updatePlusCodes: {
          longtypeCode: filteredLongTypeCode,
          options: filteredPlusCodeValues,
        },
      })
        .then((res) => {
          addCheckboxValuetoOptions({
            longtypeCode: filteredLongTypeCode,
            options: filteredPlusCodeValues,
          });
          setShowDeleteIcon(false);
          setShowAllCheckboxValues(false);
          setTimeout(() => {
            updateProjectApiCall({
              longtypeCode: filteredLongTypeCode,
              options: filteredPlusCodeValues,
            });
          }, 0);
          notificationManager.success('Plus codes has been deleted.');
        })
        .catch((error) => {});
    }
  };

  const handleCheckboxValues = (
    plusCode: string,
    e: { target: { checked: boolean } },
  ) => {
    plusCodeValues?.options?.forEach(
      (plusCodeValue: { plusCode: string; checked: boolean }) => {
        if (plusCodeValue.plusCode === plusCode) {
          setPLusCodeValues((prevPlusCodeValues: any) => {
            const updatedOptions = prevPlusCodeValues?.options?.map(
              (item: { plusCode: string; checked: boolean }) =>
                item.plusCode === plusCode
                  ? { ...item, checked: e.target.checked } // Update checked value for the matching item
                  : item, // Keep other items unchanged
            );

            // Check if at least one option is checked
            const isAnyOptionChecked = updatedOptions.some(
              (item: { checked: boolean }) => item.checked,
            );
            setShowDeleteIcon(isAnyOptionChecked);
            // Update an additional state for the checked status

            //number of selected options
            updatedOptions?.filter((item: { checked: boolean }) => item.checked)
              ?.length === prevPlusCodeValues?.options?.length
              ? setShowAllCheckboxValues(true)
              : setShowAllCheckboxValues(false);
            return {
              longtypeCode: plusCodeValues.longtypeCode,
              options: updatedOptions,
            };
          });
        }
      },
    );
  };

  return (
    <>
      <div className="drive-config-results">
        {isLoading && (
          <div className="loading-indicator-container">
            <CommonLoader
              size="xsmall"
              indicatorType="circular"
              progressType="indeterminate"
              type="primary-black"
              progressValue={undefined}
              dataTestID="drive-result-loading-indicator"
            />
            <p>Copying plus codes</p>
          </div>
        )}

        <div className="selection-header">
          <h5 className="selection-title">Selected options</h5>
        </div>
        <section className="drive-result-table">
          <div className="drive-result-table-header">
            {plusCodeValues && plusCodeValues?.options?.length > 0 ? (
              <p>{plusCodeValues?.options?.length} OPTION ADDED</p>
            ) : (
              <p>No Pluscodes Added</p>
            )}
            <section className="drive-result-table-icons">
              {showDeleteIcon && (
                <div className="delete-icon-container">
                  <Button
                    size="small"
                    type="discreet-black"
                    onClick={handleDelete}
                  >
                    <Icon name="trash" size="small" />
                  </Button>
                </div>
              )}
            </section>
          </div>

          <DataGrid
            divider="row"
            size="medium"
            type="primary-black"
            scrollable={false}
          >
            <DataGridRow is-header>
              <DataGridHeader colWidth={32} colResizable={false}>
                <div slot="checkbox-container">
                  <Checkbox
                    size="small"
                    type="primary-black"
                    onCheckboxChange={allSelectPlusCodeOptions}
                    id="select-all-checkboxs"
                    checked={showAllCheckboxValues}
                    disabled={
                      plusCodeValues?.options?.length === 0 ? true : false
                    }
                  />
                </div>
              </DataGridHeader>

              <DataGridHeader
                label="CODE"
                colResizable={false}
                colWidth={120}
              />
              <DataGridHeader
                label="DESCRIPTION"
                colResizable={false}
                colWidth={'100%'}
              />
              {/* commented as these are needed later and discussed same with design team */}
              {/* <DataGridHeader label="" colResizable={false} colWidth={64} />  */}
            </DataGridRow>
            <div>
              {plusCodeValues && plusCodeValues?.options?.length > 0 ? (
                plusCodeValues?.options?.map(
                  (
                    typecode: {
                      plusCode: string;
                      description: string;
                      checked: boolean;
                    },
                    index: number,
                  ) => (
                    <DataGridRow
                      row-color={index % 2 === 0 ? 'white' : 'grey'}
                      key={index}
                      data-testid="data-grid-rows"
                      className="data-grid"
                    >
                      <DataGridCell>
                        <div slot="checkbox-container">
                          <Checkbox
                            size="small"
                            type="primary-black"
                            onCheckboxChange={(e) =>
                              handleCheckboxValues(typecode.plusCode, e)
                            }
                            checked={typecode.checked}
                            id={`plusCode-value-${index}`}
                            data-testid="plus-code-checkbox"
                            data-rf="plus-code-checkbox"
                          />
                        </div>
                      </DataGridCell>
                      <DataGridCell className="drivetype-pluscode">
                        {typecode.plusCode}
                      </DataGridCell>
                      <DataGridCell
                        noOfLinesForTruncation={0}
                        className="drivetype-pluscode-description"
                      >
                        {typecode.description}
                      </DataGridCell>
                      {/* <DataGridCell className="icon-menu-container">
                        <Button type="discreet-black" size="xsmall">
                          <Icon
                            name="menu-narrow"
                            size="small"
                            theme="dark"
                            slot="icon"
                          ></Icon>
                        </Button>
                      </DataGridCell> */}
                    </DataGridRow>
                  ),
                )
              ) : (
                <></>
              )}
            </div>
          </DataGrid>
        </section>
      </div>
    </>
  );
};

export default DriveResults;
