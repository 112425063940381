import DualSectionLayout from '../../../../layout/components/DualSectionLayout';
import Icon from '../../../../components/Icon';
import Input from '../../../../components/Form/Input';
import SelectContainer from '../../../../components/Form/SelectContainer';
import Select from '../../../../components/Form/Select';
import SelectOption from '../../../../components/Form/SelectOption';
import { RootState } from '../../../../store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  Dimension,
  MotorLoadData,
  MotorSelectionData,
  TransformerSelectionProps,
} from '../models/MotorSelectionModels';
import {
  ApplicationDriveMotorType,
  TransformerInput,
  TransformerSelectionInput,
} from '../models/TransformerSelectionModel';
import {
  frequencyDropdownData,
  initialApplicationData,
  supplyLoadDropdownAt50HzData,
  supplyLoadDropdownAt60HzData,
} from '../models/TransformerDefaultValues';
import TransformerSelectionResult from './TransformerSelectionResult';
import {
  useGetTransformerSizingMutation,
  useReselectDimensionDataMutation,
} from '../services/APIDimensioningService';
import {
  dimensionMotorInputData,
  dimensionOutputData,
  driveFormDataSave,
  driveLoadDataSave,
  motorFormDataSave,
  motorLoadDataSave,
  productAndTypeCodeSave,
  resetDriveErrorResponse,
  resetMotorErrorResponse,
  saveDriveErrorResponse,
  saveMotorErrorResponse,
  selectDrive,
  selectMotor,
  setActiveTab,
  setApplicationActiveTabs,
  setconfigureDriveTabStatus,
  setCurrentApplication,
  setFetchedTransformerResult,
  setHasAnyApplicationSized,
  setHasTransformerReselected,
  setHasTransformerSizingErrorResponse,
  setPlusCodeOptionValues,
  setPreviousApplicationsDriveMotorType,
  setSupplyLoadDropdownOptions,
  setTransformerInputData,
  updateProductTypeDriveCode,
} from '../../store/WorkspaceReducer';
import { useSession } from '../../../../utils/Auth';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import Button from '../../../../components/Form/Button';
import notificationManager from '../../../../utils/NotificationManager';
import {
  DriveLoadData,
  DriveSelectionData,
} from '../models/DriveSelectionModels';
import { store } from '../../../../store/store';
import ReselectionConfirmationModal from './ReselectConfirmationModal';
import { handleLoadTableChanges } from '../models/MotorDefaultValues';
import { usePostPlusCodeDataByIdMutation } from '../../services/DrivePluscodeService';

const TransformerSelection = ({ isSidebarOpen }: TransformerSelectionProps) => {
  const dispatch = useDispatch();
  const { session } = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const [viewReselectButton, setViewReselectButton] = useState(false);
  const [viewReselectConfirmationPopup, setViewReselectConfirmationPopup] =
    useState(false);
  const workspace = useSelector((state: RootState) => state.workspace);
  const [viewResult, setViewResult] = useState(false);
  const [hasTransformerInputChanged, setHasTransformerInputChanged] =
    useState(false);
  const [isReselectLoading, setIsReselectLoading] = useState(false);
  const [transformerSizing, transformerSizingStatus] =
    useGetTransformerSizingMutation();
  const [updateProject] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const [reselectDimensioningData] = useReselectDimensionDataMutation({
    fixedCacheKey: 'shared-dimension-post',
  });
  const [updatePlusCode] = usePostPlusCodeDataByIdMutation({
    fixedCacheKey: 'shared-update-post',
  });

  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );
  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas,
  );
  const previousApplicationDriveMotorType = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.applicationDriveMotorType,
  );

  const hasAnyApplicationSized = useSelector(
    (state: RootState) => state.workspace.hasAnyApplicationSized,
  );

  const ambientConditionValues = useSelector(
    (state: RootState) => state.workspace.ambientConditionValues,
  );

  const applicationDetails = useSelector(
    (state: RootState) => state?.workspace?.applicationDetails,
  );

  const supplyLoadDropdownOptions = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.supplyLoadDropdownOptions,
  );

  useEffect(() => {
    applicationDetails.forEach((i) => {
      if (
        i.dimensionDetails?.dimensionOutputData?.supplyDriveLoads?.[0]
          ?.motorUnit?.results?.[0]?.value ||
        i.dimensionDetails?.dimensionOutputData?.supplyDriveLoads?.[0]
          ?.inverterUnit?.results?.[0]?.value
      ) {
        dispatch(setHasAnyApplicationSized(true));
      }
    });
  }, []);

  const handleTransformerInputOnChange = (
    event: string,
    inputType: keyof TransformerInput,
  ) => {
    let newTransformerData = {
      ...selectedTransformerDatas?.transformerInput,
    } as TransformerInput;
    if (inputType === 'frequency') {
      if (event === '60') {
        let defaultOption =
          supplyLoadDropdownAt60HzData?.[0]?.selectInput?.filter(
            (option) => option.isDefault === true,
          );
        newTransformerData.secondaryVoltage = defaultOption?.[0].value;
      } else {
        let defaultOption =
          supplyLoadDropdownAt50HzData?.[0]?.selectInput?.filter(
            (option) => option.isDefault === true,
          );
        newTransformerData.secondaryVoltage = defaultOption?.[0].value;
      }
    }
    newTransformerData[inputType as keyof TransformerInput] = event;

    let newTransformerSelectionInput: TransformerSelectionInput = {
      transformerInput: newTransformerData,
      selectionInputs: [],
    };

    applicationDetails.forEach((appDetail) => {
      const selectionInputs =
        appDetail?.dimensionDetails?.dimensionInputData?.selectionInputs;
      if (selectionInputs) {
        newTransformerSelectionInput?.selectionInputs?.push(...selectionInputs);
      }
    });
    dispatch(setTransformerInputData(newTransformerSelectionInput));
    setHasTransformerInputChanged(true);
  };

  useEffect(() => {
    if (selectedTransformerDatas?.transformerInput?.frequency === '50') {
      dispatch(setSupplyLoadDropdownOptions(supplyLoadDropdownAt50HzData));
    } else {
      dispatch(setSupplyLoadDropdownOptions(supplyLoadDropdownAt60HzData));
    }
  }, [selectedTransformerDatas]);

  useEffect(() => {
    if (!isLoading) {
      let shouldTransformerSize = false;
      let updatedPreviousDriveMotorTypes: ApplicationDriveMotorType[] = [];
      for (let i = 0; i < applicationDetails?.length; i++) {
        if (
          previousApplicationDriveMotorType.length > 0 &&
          previousApplicationDriveMotorType.length !==
            applicationDetails?.length
        ) {
          if (
            Boolean(
              applicationDetails[i]?.dimensionDetails?.productTypeCode
                ?.driveTypeCode,
            ) ||
            Boolean(
              applicationDetails[i]?.dimensionDetails?.productTypeCode
                ?.motorTypeDesignation,
            )
          ) {
            updatedPreviousDriveMotorTypes = [
              ...updatedPreviousDriveMotorTypes,
              {
                driveTypeCode:
                  applicationDetails[i]?.dimensionDetails?.productTypeCode
                    ?.driveTypeCode,
                motorTypeDesignation:
                  applicationDetails[i]?.dimensionDetails?.productTypeCode
                    ?.motorTypeDesignation,
              },
            ];

            shouldTransformerSize = true;
          } else {
            shouldTransformerSize = false;
          }
        } else {
          if (
            Boolean(
              applicationDetails[i]?.dimensionDetails?.productTypeCode
                ?.driveTypeCode,
            ) ||
            Boolean(
              applicationDetails[i]?.dimensionDetails?.productTypeCode
                ?.motorTypeDesignation,
            )
          ) {
            if (previousApplicationDriveMotorType.length > 0) {
              if (
                applicationDetails[i]?.dimensionDetails?.productTypeCode
                  ?.driveTypeCode ===
                  previousApplicationDriveMotorType?.[i]?.driveTypeCode &&
                applicationDetails[i]?.dimensionDetails?.productTypeCode
                  ?.motorTypeDesignation ===
                  previousApplicationDriveMotorType?.[i]?.motorTypeDesignation
              ) {
                shouldTransformerSize = false;
              } else {
                updatedPreviousDriveMotorTypes = [
                  ...updatedPreviousDriveMotorTypes,
                  {
                    driveTypeCode:
                      applicationDetails[i]?.dimensionDetails?.productTypeCode
                        ?.driveTypeCode,
                    motorTypeDesignation:
                      applicationDetails[i]?.dimensionDetails?.productTypeCode
                        ?.motorTypeDesignation,
                  },
                ];
                shouldTransformerSize = true;
              }
            } else {
              updatedPreviousDriveMotorTypes = [
                ...updatedPreviousDriveMotorTypes,
                {
                  driveTypeCode:
                    applicationDetails[i]?.dimensionDetails?.productTypeCode
                      ?.driveTypeCode,
                  motorTypeDesignation:
                    applicationDetails[i]?.dimensionDetails?.productTypeCode
                      ?.motorTypeDesignation,
                },
              ];

              shouldTransformerSize = true;
            }
          }
        }
      }

      if (updatedPreviousDriveMotorTypes?.length === 0) {
      } else {
        dispatch(
          setPreviousApplicationsDriveMotorType(updatedPreviousDriveMotorTypes),
        );
      }

      if (shouldTransformerSize) {
        handleTransformerResizeCall();
      }
    }
  }, []);

  useEffect(() => {
    if (hasAnyApplicationSized) {
      if (hasTransformerInputChanged) {
        setViewReselectButton(true);

        setHasTransformerInputChanged(false);
      }
    }
  }, [hasAnyApplicationSized, hasTransformerInputChanged]);

  const handleTransformerResizeCall = () => {
    dispatch(setHasTransformerSizingErrorResponse(false));
    if (!transformerSizingStatus.isLoading) {
      setIsLoading(true);
      let transformerMultipleDimensionInput: TransformerSelectionInput = {
        ambientInputs: {
          ambientAltitude: ambientConditionValues.altitude,
          ambientTempInverter: ambientConditionValues.driveTemparature,
          ambientTempMotor: ambientConditionValues.motorTemparature,
        },
        transformerInput: selectedTransformerDatas?.transformerInput ?? {},
        selectionInputs: [
          ...applicationDetails?.map((application) => {
            let appDimensionInput =
              application.dimensionDetails?.dimensionInputData
                ?.selectionInputs?.[0];
            if (appDimensionInput && appDimensionInput?.appId) {
              if (
                Object.keys(appDimensionInput)?.length > 0 &&
                (('Compute' in appDimensionInput &&
                  appDimensionInput.Compute !== undefined) ||
                  ('compute' in appDimensionInput &&
                    appDimensionInput.compute !== undefined))
              ) {
                return {
                  ...appDimensionInput,
                  Compute: 'true',
                  dataCategory:
                    appDimensionInput.power !== '0'
                      ? appDimensionInput.dataCategory === 'Motor'
                        ? 'Motor'
                        : 'All'
                      : 'Drive',
                };
              } else return initialApplicationData;
            } else {
              return initialApplicationData;
            }
          }),
        ],
      };

      transformerSizing(transformerMultipleDimensionInput).then((response) => {
        if (
          response.data &&
          response !== null &&
          Object.keys(response.data).length > 0
        ) {
          dispatch(setFetchedTransformerResult(response.data));

          updateProject({
            userToken: session ? session?.user.access_token : undefined,
            projectData: {
              projectId: projectDetails.projectId,
              projectName: projectDetails.projectName,
              jsonFileName: selectedTransformerDatas?.transformerInput?.trafoId,
              projectJson: JSON.stringify({
                transformerInput: selectedTransformerDatas?.transformerInput,
                transformerResult:
                  response.data?.transformer?.transformerResult,
              }),
            },
          });

          setIsLoading(false);
          dispatch(setHasTransformerSizingErrorResponse(false));
        } else {
          setIsLoading(false);
          dispatch(setHasTransformerSizingErrorResponse(true));
        }
      });
    }
  };

  const handleReselectClick = () => {
    setViewReselectConfirmationPopup(true);
    setViewReselectButton(false);
  };

  const onConfirmationModalClose = () => {
    setViewReselectConfirmationPopup(false);
  };

  const handleReselectConfirmClick = () => {
    setIsLoading(true);
    setIsReselectLoading(true);

    reselectDimensioningData({
      transformerInput: selectedTransformerDatas?.transformerInput,
      selectionInputs: [
        ...(applicationDetails?.map((application) => {
          const dimensionDetails = application?.dimensionDetails;
          const dimensionInputData = dimensionDetails?.dimensionInputData;
          const selectionInputs = dimensionInputData?.selectionInputs;

          const appDimensionInput = selectionInputs?.[0];
          if (appDimensionInput && appDimensionInput?.appId) {
            if (
              Object.keys(appDimensionInput)?.length > 0 &&
              ('Compute' in appDimensionInput || 'compute' in appDimensionInput)
            ) {
              return {
                ...appDimensionInput,
                dataCategory: appDimensionInput.power !== '0' ? 'All' : 'Drive',
                iMax:
                  appDimensionInput.power !== '0'
                    ? undefined
                    : appDimensionInput.iMax,
                iContinuous:
                  appDimensionInput.power !== '0'
                    ? undefined
                    : appDimensionInput.iContinuous,
                maxSpeed: appDimensionInput.power !== '0' ? null : undefined,
              };
            } else return initialApplicationData;
          } else {
            return initialApplicationData;
          }
        }) ?? []),
      ],
      ambientInputs: {
        ambientAltitude: ambientConditionValues.altitude,
        ambientTempInverter: ambientConditionValues.driveTemparature,
        ambientTempMotor: ambientConditionValues.motorTemparature,
      },
    })
      .then((response) => {
        const dimension = response?.data?.dimension;
        const dimensionInput = dimension?.dimensionInput;
        const dimensionResult = dimension?.dimensionResult;
        const supplyDriveLoads = dimensionResult?.supplyDriveLoads;
        if (response.data && supplyDriveLoads && supplyDriveLoads.length > 0) {
          dispatch(
            setFetchedTransformerResult({
              transformer: {
                transformerResult: [
                  {
                    typeDesignation: '-',
                    selectionData:
                      dimensionResult?.transformerUnit?.selectionData,
                  },
                ],
              },
            }),
          );
          dispatch(setHasTransformerReselected(true));
          const thunkFunction = (dispatch: any, getState: any) => {
            const applicationResultSet = supplyDriveLoads;

            const allApplications = getState().workspace.applicationDetails;

            updateProject({
              userToken: session ? session?.user.access_token : undefined,
              projectData: {
                projectId: projectDetails.projectId,
                projectName: projectDetails.projectName,
                jsonFileName:
                  selectedTransformerDatas?.transformerInput?.trafoId,
                projectJson: JSON.stringify({
                  transformerInput: selectedTransformerDatas?.transformerInput,
                  transformerResult:
                    dimensionResult?.transformerUnit?.selectionData,
                }),
              },
            });
            applicationResultSet.forEach((i, index) => {
              const applicationId = i.appId;
              const rank = allApplications.filter(
                (x: any) => x.id === applicationId,
              )?.[0]?.rank;

              let allResultData: Dimension = {
                dimension: {
                  dimensionInput: {
                    selectionInputs: dimensionInput?.selectionInputs?.[index]
                      ? [dimensionInput?.selectionInputs?.[index]]
                      : [],
                    transformerInput: dimensionInput?.transformerInput,
                  },
                  dimensionResult: { supplyDriveLoads: [i] },
                },
                exceptionType: response?.data?.exceptionType,
                message: response?.data?.message,
              };

              if (rank > -1) {
                dispatch(setCurrentApplication(rank));

                let motorLoadData = dimensionInput?.selectionInputs?.[
                  index
                ] as unknown as MotorLoadData;
                motorLoadData = handleLoadTableChanges(
                  dimensionInput?.selectionInputs?.[index]?.power ?? '0',
                  'power',
                  motorLoadData,
                );

                dispatch(motorLoadDataSave(motorLoadData));

                dispatch(
                  motorFormDataSave(
                    dimensionInput?.selectionInputs?.[
                      index
                    ] as unknown as MotorSelectionData,
                  ),
                );

                dispatch(selectMotor(allResultData));

                dispatch(dimensionOutputData({ supplyDriveLoads: [i] }));

                dispatch(
                  dimensionMotorInputData({
                    selectionInputs: [dimensionInput?.selectionInputs?.[index]],
                  }),
                );

                const inverterUnitResult = i.inverterUnit?.results;

                dispatch(
                  productAndTypeCodeSave({
                    productCode: i?.motorUnit?.results?.[1]?.value ?? null,
                    motorTypeDesignation:
                      i?.motorUnit?.results?.[0]?.value ?? null,
                    driveTypeCode: inverterUnitResult
                      ? (inverterUnitResult?.[0]?.value ?? null)
                      : '',
                  }),
                );

                if (
                  dimensionInput?.selectionInputs?.[index]?.dataCategory !==
                  'Motor'
                ) {
                  dispatch(
                    driveLoadDataSave(
                      dimensionInput?.selectionInputs?.[
                        index
                      ] as unknown as DriveLoadData,
                    ),
                  );
                  dispatch(
                    driveFormDataSave(
                      dimensionInput?.selectionInputs?.[
                        index
                      ] as unknown as DriveSelectionData,
                    ),
                  );

                  const motorError = i?.motorUnit?.motorError;
                  const driveError = i?.inverterUnit?.invError;
                  if (!!motorError) {
                    dispatch(
                      saveMotorErrorResponse({
                        errorMotorSelection: motorError,
                        isError: motorError.toLowerCase().includes('error'),
                        isWarning: !motorError.toLowerCase().includes('error'),
                      }),
                    );
                  } else {
                    dispatch(resetMotorErrorResponse());
                  }

                  if (!!driveError) {
                    dispatch(
                      saveDriveErrorResponse({
                        errorDriveSelection: driveError,
                        isError: driveError.toLowerCase().includes('error'),
                        isWarning: !driveError.toLowerCase().includes('error'),
                      }),
                    );
                  } else {
                    dispatch(resetDriveErrorResponse());
                  }
                  dispatch(selectDrive(allResultData));
                }
                //updating the drivepluscode data of each application
                //each if each applications drive plus codes drive values are same.
                const drivePlsuCodeOptions = [];
                applicationDetails?.forEach((application, index) => {
                  if (application?.id === i?.appId) {
                    if (
                      i?.inverterUnit?.results?.[0]?.value !==
                      application?.dimensionDetails?.selectDriveResult
                        ?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                        ?.inverterUnit?.results?.[0]?.value
                    ) {
                      dispatch(setPlusCodeOptionValues({}));
                      dispatch(setApplicationActiveTabs([]));
                      dispatch(setActiveTab('selection'));
                      dispatch(setconfigureDriveTabStatus(false));
                      updatePlusCode({
                        applicationId: i?.appId,
                        updatePlusCodes: {
                          longtypeCode: '',
                          options: [],
                        },
                      });
                    } else if (
                      application?.drivePlusCodeObject?.options &&
                      application?.drivePlusCodeObject?.options?.length > 0
                    ) {
                      //can we take the details
                      dispatch(
                        updateProductTypeDriveCode(
                          application?.drivePlusCodeObject?.longtypeCode,
                        ),
                      );
                    }
                  }
                });

                updateProject({
                  userToken: session?.user
                    ? session?.user.access_token
                    : undefined,
                  projectData: {
                    projectId:
                      (projectDetails.projectId ||
                        window.sessionStorage.getItem('id')) ??
                      '',
                    projectName:
                      (projectDetails.projectName ||
                        window.sessionStorage.getItem('name')) ??
                      '',
                    jsonFileName:
                      index > 0
                        ? applicationDetails[index]?.id
                        : applicationDetails[0]?.id,
                    applicationDetailsJson: JSON.stringify({
                      transformerId:
                        selectedTransformerDatas?.transformerInput?.trafoId,
                      applicationId:
                        index > 0
                          ? applicationDetails[index]?.id
                          : applicationDetails[0]?.id,
                      applicationName:
                        index > 0
                          ? applicationDetails[index]?.name
                          : applicationDetails[0]?.name,
                      applicationRank: index > 0 ? index : 0,
                    }),
                    projectJson: JSON.stringify({
                      dimensionSavedData: {
                        dimensionInput:
                          dimensionInput?.selectionInputs?.[index],
                        applicationOutput: {
                          supplyDriveLoads: [supplyDriveLoads?.[index]],
                        },

                        exceptionType: response?.data?.exceptionType,
                        message: response?.data?.message,
                      },
                      plusOptions:
                        i?.inverterUnit?.results?.[0]?.value ===
                        applicationDetails[index]?.dimensionDetails
                          ?.selectDriveResult?.dimension?.dimensionResult
                          ?.supplyDriveLoads?.[0]?.inverterUnit?.results?.[0]
                          ?.value
                          ? {
                              longTypeCode:
                                applicationDetails[index]?.drivePlusCodeObject
                                  ?.longtypeCode,
                              options:
                                applicationDetails[index]?.drivePlusCodeObject
                                  ?.options,
                            }
                          : null,
                    }),
                  },
                })
                  .then((response) => {})
                  .catch((error) => {
                    setIsLoading(false);
                    setIsReselectLoading(false);
                    notificationManager.error(
                      'Unable to update application data',
                      error?.data?.toString(),
                    );
                  });
              }

              if (index === applicationResultSet?.length - 1)
                dispatch(setCurrentApplication(-1));
            });
            setIsLoading(false);
            setIsReselectLoading(false);
          };
          store.dispatch(thunkFunction);
          // setup if we need to call plus code
        } else {
          setIsLoading(false);
          setIsReselectLoading(false);
          notificationManager.error('Unable to retrieve dimension data');
        }
      })
      .catch((error) => {
        notificationManager.error(
          'Unable to retrieve dimension data',
          error?.data?.toString(),
        );
      });
  };

  const getLeftSection = () => {
    return (
      <>
        <div>
          <div className="transformer-data-selection-header">
            <Icon slot="icon" size="small" name={'transformer'} />
            <div className="transformer-data-selection-header-text">
              {'Supply data selection'}
            </div>
          </div>
          <div className="form">
            <div className="transformer-subtitle-container" slot="left">
              <div
                className="transformer-info-notification"
                hidden={hasAnyApplicationSized}
              >
                <img className="transformer-info-icon" src="/infoblue.svg" />
                <div>
                  Update supply data by checking frequency and secondary voltage
                  before selecting and sizing drive and motor for your
                  application.
                </div>
              </div>
              <div className="transformer-subsection-container">
                {frequencyDropdownData.map((frequencyDropdownItems) => {
                  return (
                    <div>
                      <div className="transformer-subsection-header">
                        {'Network data'}
                      </div>
                      <div className="transformer-select-header">
                        {frequencyDropdownItems.label}
                      </div>
                      <div>
                        <SelectContainer
                          size="small"
                          className="transformer-subsection-container-area"
                          disabled={isLoading}
                        >
                          <Select
                            slot="main"
                            required={true}
                            disabled={isLoading}
                            value={
                              selectedTransformerDatas?.transformerInput?.[
                                'frequency'
                              ]
                            }
                          >
                            <div slot="options-main">
                              {frequencyDropdownItems.selectInput.map(
                                (frequencyOptions) => {
                                  return (
                                    <SelectOption
                                      data-value={frequencyOptions.value}
                                      optionText={frequencyOptions.label}
                                      onOptionClick={(event) =>
                                        handleTransformerInputOnChange(
                                          event.target.outerText,
                                          'frequency',
                                        )
                                      }
                                      selected={
                                        frequencyOptions.value ===
                                        selectedTransformerDatas
                                          ?.transformerInput?.['frequency']
                                      }
                                    />
                                  );
                                },
                              )}
                            </div>
                          </Select>
                        </SelectContainer>
                      </div>{' '}
                    </div>
                  );
                })}

                <div>
                  <Input
                    label-text={'Short-circuit power'}
                    className="transformer-input-text"
                    size="small"
                    valid={true}
                    suffixText={'MVA'}
                    onInputChange={(event) =>
                      handleTransformerInputOnChange(
                        event.detail.value,
                        'networkSk',
                      )
                    }
                    required={false}
                    inputType="number"
                    value={
                      selectedTransformerDatas?.transformerInput?.networkSk
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="transformer-subtitle-container" slot="left">
              <div className="transformer-subsection-header">
                {'Supply load'}
              </div>
              <Input
                label-text={'Primary voltage'}
                className="transformer-input-text"
                size="small"
                valid={true}
                suffixText={'V'}
                onInputChange={(event) =>
                  handleTransformerInputOnChange(
                    event.detail.value,
                    'primaryVoltage',
                  )
                }
                required={false}
                inputType="number"
                value={
                  selectedTransformerDatas?.transformerInput?.primaryVoltage
                }
                disabled={true}
              />
              {supplyLoadDropdownOptions?.map((supplyLoadDropdown) => {
                return (
                  <div className="transformer-subsection-container">
                    <div className="transformer-select-header">
                      {supplyLoadDropdown.label}
                    </div>
                    <SelectContainer
                      size="small"
                      className="transformer-subsection-container-area"
                      disabled={isLoading}
                    >
                      <Select
                        slot="main"
                        required={true}
                        value={
                          selectedTransformerDatas?.transformerInput?.[
                            supplyLoadDropdown.accessor as keyof TransformerInput
                          ]
                        }
                        disabled={
                          supplyLoadDropdown.accessor === 'trafoWindingNo' ||
                          isLoading
                        }
                      >
                        <div slot="options-main">
                          {supplyLoadDropdown?.selectInput?.map((option) => {
                            return (
                              option?.value !== '0' && (
                                <SelectOption
                                  data-value={option?.value}
                                  optionText={option?.label}
                                  onOptionClick={(event) =>
                                    handleTransformerInputOnChange(
                                      event.target.outerText,
                                      supplyLoadDropdown.accessor as keyof TransformerInput,
                                    )
                                  }
                                  selected={
                                    option?.value ===
                                    selectedTransformerDatas
                                      ?.transformerInput?.[
                                      supplyLoadDropdown.accessor as keyof TransformerInput
                                    ]
                                  }
                                />
                              )
                            );
                          })}
                        </div>
                      </Select>
                    </SelectContainer>
                  </div>
                );
              })}
            </div>
            {/* <div className="transformer-subtitle-container" slot="left">
              <div className="transformer-subsection-header">
                {'Fundamental power'}
              </div>
              <div className="dsu-load-input-container">
                <Input
                  label-text={'DSU load'}
                  className="dsu-input-text"
                  size="small"
                  valid={true}
                  onInputChange={(event) => {}}
                  required={true}
                  inputType="number"
                  value={''}
                />
                <Input
                  label-text={'Calculated'}
                  className="calculated-input-text"
                  size="small"
                  valid={true}
                  onInputChange={(event) => {}}
                  required={true}
                  inputType="number"
                  value={''}
                  disabled
                />
              </div>
            </div> */}
          </div>
        </div>
        {viewReselectButton && (
          <div className="transformer-reselect-button">
            <Button type="primary-black" onClick={handleReselectClick}>
              Reselect
            </Button>
          </div>
        )}
        {viewReselectConfirmationPopup && (
          <div>
            <ReselectionConfirmationModal
              modalOpen={viewReselectConfirmationPopup}
              onClose={onConfirmationModalClose}
              onConfirmClick={handleReselectConfirmClick}
              contentText="Inputs changed for supply data, This will reselect all the applications ."
            />
          </div>
        )}
      </>
    );
  };
  return (
    <div>
      {
        <div className="transformer-container">
          <DualSectionLayout
            leftChildren={getLeftSection()}
            rightChildren={
              <>
                <TransformerSelectionResult
                  isLoading={isLoading}
                  handleRetryCalculate={handleTransformerResizeCall}
                  isReselectLoading={isReselectLoading}
                />
              </>
            }
            isSidebarOpen={workspace.sidebarOpen}
            viewResult={viewResult}
          />
        </div>
      }
    </div>
  );
};

export default TransformerSelection;
