import { useEffect, useState, memo } from 'react';
import {
  Dimension,
  SelectionInput,
  LoadCycle,
} from '../models/MotorSelectionModels';
import DualSectionLayout from '../../../../layout/components/DualSectionLayout';
import ButtonGroup from '../../../../components/Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
  productAndTypeCodeSave,
  selectMotor,
  dimensionOutputData,
  dimensionMotorInputData,
  dimensionDataLoading,
  recommendedMotorList,
  selectDrive,
  driveLoadDataSave,
  saveMotorErrorResponse,
  resetMotorErrorResponse,
  saveDriveErrorResponse,
  recommendedDriveList,
  resetDriveErrorResponse,
  setHasRetryCatalogDataSelected,
  setPlusCodeOptionValues,
  setApplicationActiveTabs,
  setActiveTab,
  setconfigureDriveTabStatus,
  setEfficiencyTabStatus,
  updateProductTypeDriveCode,
} from '../../store/WorkspaceReducer';
import MotorSelectionResult from './MotorSelectionResult';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import ButtonGroupMobile from '../../../../components/Form/ButtonGroupMobile';
import notificationManager from '../../../../utils/NotificationManager';
import { MotorSelectionForm } from './MotorSelectionForm';
import {
  motorFormDataDefaults,
  motorLoadDataGPDDefaults,
} from '../models/MotorDefaultValues';
import { RootState } from '../../../../store/rootReducer';
import {
  useRecommendedMotorDataMutation,
  useSelectAllMutation,
} from '../services/APIDimensioningService';
import {
  useGetCatalogReportMutation,
  useGetPISDataMutation,
} from '../../../Home/services/APICommonService';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import { useSession } from '../../../../utils/Auth';
import {
  DimensionInput,
  TransformerDimensionProjectIdData,
} from '../models/TransformerSelectionModel';
import { defaultMachineryDriveFormData } from '../models/DriveDefaultValues';
import { Blocker } from 'react-router-dom';
import { appInsights } from '../../../../services/AppInsight';
import { DriveLoadData } from '../models/DriveSelectionModels';
import { usePostPlusCodeDataByIdMutation } from '../../services/DrivePluscodeService';

const MotorSelectionComponent = ({ blocker }: { blocker: Blocker }) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { isMobile } = useDeviceDetect();
  const { session } = useSession();
  const [viewResult, setViewResult] = useState(false);
  const [viewManualSelection, setViewManualSelection] = useState(false);
  const [isSelectMotorClicked, setIsSelectMotorClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isManualSelectionLoading, setIsManualSelectionLoading] =
    useState(false);
  const [isGetDimensionLoading, setIsGetDimensionLoading] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [hasNoResponse, setHasNoResponse] = useState(false);

  useEffect(() => {
    appInsights.trackPageView({
      name: 'Motor selection page',
      pageType: 'Sub page',
    });
  }, []);

  const [dimensioningData] = useSelectAllMutation({
    fixedCacheKey: 'shared-dimension-post',
  });
  const [updateProject] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const [updatePlusCode] = usePostPlusCodeDataByIdMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const [catalogueData, catalogStatus] = useGetPISDataMutation({
    fixedCacheKey: 'shared-catalogue-post',
  });

  const [catalogReport, status] = useGetCatalogReportMutation({
    fixedCacheKey: 'shared-dimension-post',
  });

  const [manualSelectionMotorData] = useRecommendedMotorDataMutation();

  const projectId = window.sessionStorage.getItem('id');

  const applicationDetails = useSelector(
    (state: RootState) =>
      state?.workspace?.applicationDetails[state.workspace.currentAppRank],
  );

  const manualSelectionData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.manualSelectionMotorData,
  );
  const motorFormData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.motorFormData,
  );
  const dimensionInputData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dimensionInputData,
  );
  const driveFormData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveFormData,
  );
  const driveLoadData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveLoadData,
  );
  const motorParamsData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.motorParamsData,
  );
  const dimensionDetails = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails,
  );
  const updatedLoadFields = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.motorLoadData,
  );
  const dutyCycleMotorValues = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dutyCycleMotorValues,
  );
  const dutyCycleDriveValues = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dutyCycleDriveValues,
  );
  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );
  const selectionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .selectionDetails;
  });
  const motorResultData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.selectMotorResult,
  );
  const hasDimensionDataFetched = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.hasDimensionDataFetched,
  );

  const workspace = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  const sidebarOpen = useSelector(
    (state: RootState) => state.workspace.sidebarOpen,
  );

  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput,
  );

  const ambientConditionValues = useSelector(
    (state: RootState) => state.workspace.ambientConditionValues,
  );

  const hasTransformerReselected = useSelector(
    (state: RootState) => state.workspace.hasTransformerReselected,
  );

  const hasRetryCatalogDataSelected = useSelector(
    (state: RootState) => state.workspace.hasRetryCatalogDataSelected,
  );

  const onSelectClick = () => {
    //overLoadType 2 is one-time
    if (
      updatedLoadFields.overLoadType === '2' &&
      (updatedLoadFields.oneTimeOverload === '0' ||
        updatedLoadFields.oneTimeOverloadTime === '0' ||
        updatedLoadFields.oneTimeOverloadMaxSpeed === '0')
    ) {
      notificationManager.error(
        t(I18.motor_selection_notification_one_time_overload_at_start_label),
      );
    } else {
      if (
        updatedLoadFields.power !== '0' &&
        updatedLoadFields.power !== undefined
      ) {
        setIsSelectMotorClicked(true);
        window.scrollTo(0, 0);
      } else {
        notificationManager.error(
          t(I18.motor_selection_notification_power_non_zero_label),
        );
      }
    }
    setIsSelectMotorClicked(true);
  };

  const handleSelectMotorButtonClick = () => {
    onSelectClick();
  };

  useEffect(() => {
    if (hasDimensionDataFetched) {
      if (
        motorResultData?.dimension?.dimensionInput?.selectionInputs?.[0]
          ?.dataCategory !== 'Drive' &&
        !motorResultData?.dimension?.catalogueDataMotor?.productAttributes
          ?.ProductId &&
        motorResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.motorUnit?.results?.[0]?.value !== null &&
        motorResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.motorUnit?.results?.[0]?.value !== ''
      ) {
        setIsGetDimensionLoading(false);
        setIsLoading(false);
      }
    }
  }, [hasDimensionDataFetched]);

  useEffect(() => {
    if (motorResultData) {
      if (
        (dimensionInputData?.selectionInputs?.[0]?.dataCategory ||
          motorResultData?.dimension?.dimensionInput?.selectionInputs?.[0]
            ?.dataCategory) &&
        viewManualSelection &&
        !manualSelectionData
      ) {
        setIsManualSelectionLoading(true);
        manualSelectionMotorData(dimensionInputData)
          .then((response) => {
            if (
              response.data &&
              response?.data?.motorsList?.recommendedMotors !== null
            ) {
              dispatch(recommendedMotorList(response?.data));
              setIsManualSelectionLoading(false);
            } else {
              setIsManualSelectionLoading(false);
              setHasNoResponse(true);
              notificationManager.error(
                'Unable to retrieve manual selection data',
              );
            }
          })
          .catch(() => {
            setIsManualSelectionLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dimensionInputData, viewManualSelection]);

  const updateProjectApiCall = (response: Dimension) => {
    const token = session ? session?.user.access_token : undefined;
    if (response) {
      updateProject({
        userToken: token,
        projectData: {
          projectId:
            (projectDetails.projectId || window.sessionStorage.getItem('id')) ??
            '',
          projectName:
            (projectDetails.projectName ||
              window.sessionStorage.getItem('name')) ??
            '',
          jsonFileName: workspace.id,
          projectJson: JSON.stringify({
            dimensionSavedData: {
              dimensionInput:
                response.dimension?.dimensionInput?.selectionInputs?.[0],
              applicationOutput: {
                supplyDriveLoads:
                  response?.dimension?.dimensionResult?.supplyDriveLoads,
              },
              exceptionType: response?.exceptionType,
              message: response?.message,
            },
            selectionSavedData: {
              industryName: workspace?.selectionDetails?.industryName,
              applicationName: workspace?.selectionDetails?.applicationName,
            },

            plusOptions:
              response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                ?.inverterUnit?.results?.[0]?.value ===
              workspace?.drivePlusCodeObject?.longtypeCode?.split('+')?.[0]
                ? {
                    longtypeCode:
                      workspace?.drivePlusCodeObject?.longtypeCode ?? '',
                    options: workspace?.drivePlusCodeObject?.options,
                  }
                : null,
          }),
          transformerId: selectedTransformerDatas?.trafoId,
          applicationId: workspace?.id,
          bomDetailsJson: JSON.stringify({
            AppId: workspace?.id,
            MotorId:
              response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                .motorUnit?.results?.[1]?.value,
            MotorTypeCode:
              response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                .motorUnit.results?.[0]?.value,
            MotorName:
              response?.dimension?.dimensionInput?.selectionInputs?.[0]
                .motorName,
            MotorCount:
              response.dimension?.dimensionInput?.selectionInputs?.[0]
                .motorsCount,
            DriveId:
              response.dimension.catalogueDataDrive?.productAttributes
                ?.ProductId?.values?.[0]?.text,
            DriveTypeCode:
              response.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                .inverterUnit.results?.[0]?.value ?? '',
            DriveName:
              response.dimension?.dimensionInput?.selectionInputs?.[0]
                .driveName ?? '',
            DriveCount:
              response.dimension?.dimensionInput?.selectionInputs?.[0]
                .drivesCount,
          }),
        },
      })
        .then(() => {
          blocker?.reset?.();
        })
        .catch((error) => {
          notificationManager.error(
            'Unable to autosave the project due to: ' + error.toString(),
          );
        });
    }
  };
  const handleSelectButtonClick = (
    type: string,
    manuallySelectedIndex?: string,
  ) => {
    dispatch(recommendedMotorList(undefined));
    dispatch(recommendedDriveList(undefined));
    dispatch(resetMotorErrorResponse());
    if (!isLoading) {
      setIsLoading(true);

      if (
        (updatedLoadFields.power !== '0' &&
          updatedLoadFields.power !== undefined) ||
        selectionData.power !== 0
      ) {
        let dimensionInputData: SelectionInput = {
          appId: applicationDetails.id,
          Compute: 'true',
          dataCategory: type,
          motorName: motorFormData?.motorName,
          projectType:
            workspace.drive?.driveGroup ??
            dimensionDetails.typeIds.projectType.toString(),
          motorType:
            motorFormData?.motorType?.toString() ??
            motorFormDataDefaults.motorType,
          power: updatedLoadFields?.power?.toString(),
          loadType: updatedLoadFields.loadType,
          overLoadType: updatedLoadFields.overLoadType,
          dutyCycleTime: updatedLoadFields.dutyCycleTime ?? '600',
          maxLoadTime: updatedLoadFields.maxLoadTime,
          olBaseInput: updatedLoadFields.olBaseInput,
          maxSpeed: updatedLoadFields.maxSpeed,
          olMaxInput:
            updatedLoadFields.olMaxInput ??
            motorLoadDataGPDDefaults?.olMaxInput,
          nMin: updatedLoadFields.nMin
            ? updatedLoadFields.nMin.toString()
            : updatedLoadFields?.nMin,
          nBase: updatedLoadFields.nBase
            ? updatedLoadFields.nBase.toString()
            : updatedLoadFields?.nBase,
          nMax: updatedLoadFields.nMax
            ? updatedLoadFields.nMax?.toString()
            : updatedLoadFields?.nMax,
          drivesCount: driveLoadData?.drivesCount ?? '1',
          driveName: driveLoadData?.driveName,
        };

        if (driveFormData?.type || driveFormData?.construction) {
          if (driveFormData?.type !== '0') {
            dimensionInputData = {
              ...dimensionInputData,
              type: driveFormData?.type,
              driveIPclass: driveFormData?.ipClass,
              coolingType: driveFormData?.cooling,
              construction: driveFormData?.construction,
              pulse: driveFormData?.pulse,
              sineFilter: driveFormData?.sineFilter,
            };
          } else if (driveFormData?.type === '0') {
            dimensionInputData = {
              ...dimensionInputData,
              type: driveFormData?.type,
            };
          }
        } else {
          if (dimensionDetails?.typeIds?.projectType.toString() === '7') {
            dimensionInputData = {
              ...dimensionInputData,
              type: defaultMachineryDriveFormData?.type,
            };
          } else {
            dimensionInputData = {
              ...dimensionInputData,
              type: applicationDetails.drive?.driveType,
              coolingType: applicationDetails.drive?.cooling,
              construction: applicationDetails.drive?.mountingType,
            };
          }
        }

        dimensionInputData = {
          ...dimensionInputData,
          motorsCount:
            motorFormData?.motorsCount ?? motorFormDataDefaults.motorsCount,
          meps: motorFormData?.meps ?? motorFormDataDefaults.meps,
          marineClassification:
            motorFormData?.marineClassification ?? undefined,
          frameMaterial:
            motorFormData?.frameMaterial ?? motorFormDataDefaults.frameMaterial,
          motorFamily:
            motorFormData?.motorFamily ?? motorFormDataDefaults.motorFamily,
          poleNumber:
            motorFormData?.poleNumber ?? motorFormDataDefaults.poleNumber,
          terminalBoxLocation:
            motorFormData?.terminalBoxLocation ??
            motorFormDataDefaults.terminalBoxLocation,
          efficiencyClass:
            motorFormData?.efficiencyClass ??
            motorFormDataDefaults.efficiencyClass,
          winding: motorFormData?.winding ?? motorFormDataDefaults.winding,
          design: motorFormData?.design ?? motorFormDataDefaults.design,
          connection:
            motorFormData?.connection ?? motorFormDataDefaults.connection,
          ipClass: motorFormData?.ipClass ?? motorFormDataDefaults.ipClass,
          icClass: motorFormData?.icClass ?? motorFormDataDefaults.icClass,
          imClass: motorFormData?.imClass ?? motorFormDataDefaults.imClass,
          maxSpeedRule:
            motorFormData?.maxSpeedRule ?? motorFormDataDefaults.maxSpeedRule,
          insulation:
            motorFormData?.insulation ?? motorFormDataDefaults.insulation,
          tempRiseClass:
            motorFormData?.tempRiseClass ?? motorFormDataDefaults.tempRiseClass,
          tempReserve:
            motorFormData?.tempReserve ?? motorFormDataDefaults.tempReserve,
          motorTmaxMargin:
            motorFormData?.motorTmaxMargin ??
            motorFormDataDefaults.motorTmaxMargin,
        };

        if (
          manuallySelectedIndex !== '' &&
          manuallySelectedIndex !== undefined
        ) {
          dimensionInputData = {
            ...dimensionInputData,
            motorIndex: manuallySelectedIndex,
          };
        }
        let motorLoadCycles: LoadCycle[] = [];
        if (dutyCycleMotorValues && dimensionInputData?.overLoadType === '1') {
          dimensionInputData = {
            ...dimensionInputData,
            motorLoadCycles: null,
          };
          dutyCycleMotorValues?.forEach(
            (
              row: {
                time: { toString: () => any };
                minSpeed: { toString: () => any };
                maxSpeed: { toString: () => any };
                load: { toString: () => any };
              },
              index: { toString: () => any },
            ) => {
              motorLoadCycles = [
                ...motorLoadCycles,
                {
                  rowNum: index.toString(),
                  time: row.time.toString(),
                  minSpeed: row.minSpeed.toString(),
                  maxSpeed: row.maxSpeed.toString(),
                  load: row.load.toString(),
                  cosfi: dutyCycleDriveValues?.[0]?.cosfi?.toString() ?? '0',
                  fMax: dutyCycleDriveValues?.[0]?.maxF?.toString() ?? '0',
                  fMin: dutyCycleDriveValues?.[0]?.minF?.toString() ?? '0',
                  isCosFiUpdatedByUser: 'true',
                  isCurrentUpdatedByUser: 'true',
                  isFmaxUpdatedByUser: 'true',
                  isFminUpdatedByUser: 'true',
                },
              ];
            },
          );
          dimensionInputData = {
            ...dimensionInputData,
            motorLoadCycles: motorLoadCycles,
          };
        }
        let transformerDimensionInput: DimensionInput = {
          transformerInput: selectedTransformerDatas,
          selectionInputs: [dimensionInputData],
          ambientInputs: {
            ambientAltitude: ambientConditionValues.altitude,
            ambientTempInverter: ambientConditionValues.driveTemparature,
            ambientTempMotor: ambientConditionValues.motorTemparature,
          },
        };
        dispatch(dimensionMotorInputData(transformerDimensionInput));
        dispatch(dimensionDataLoading(true));

        setIsGetDimensionLoading(true);

        let transformerDimensionProjectData: TransformerDimensionProjectIdData =
          {
            transformerDimension: transformerDimensionInput,
            projectId: projectId ?? '',
          };
        dimensioningData(transformerDimensionProjectData)
          .then((response) => {
            if (
              response.data &&
              response?.data?.dimension?.dimensionResult !== null
            ) {
              let allResultData: Dimension = {
                dimension: {
                  dimensionInput: {
                    ...transformerDimensionInput,
                  },
                  dimensionResult: response?.data?.dimension?.dimensionResult,
                },
                exceptionType: response?.data?.exceptionType,
                message: response?.data?.message,
              };

              dispatch(selectDrive(allResultData));
              dispatch(selectMotor(allResultData));
              let inverterUnitResult =
                response?.data?.dimension?.dimensionResult
                  ?.supplyDriveLoads?.[0]?.inverterUnit?.results;

              dispatch(
                dimensionOutputData(response?.data?.dimension?.dimensionResult),
              );
              dispatch(
                productAndTypeCodeSave({
                  productCode:
                    response.data?.dimension?.dimensionResult
                      ?.supplyDriveLoads?.[0]?.motorUnit?.results?.[1]?.value ??
                    null,
                  motorTypeDesignation:
                    response?.data?.dimension?.dimensionResult
                      ?.supplyDriveLoads?.[0]?.motorUnit?.results?.[0]?.value ??
                    null,
                  driveTypeCode: inverterUnitResult
                    ? (inverterUnitResult?.[0]?.value ?? null)
                    : '',
                }),
              );

              if (
                inverterUnitResult?.[0]?.value &&
                applicationDetails?.drivePlusCodeObject?.longtypeCode?.split(
                  '+',
                )?.[0] === inverterUnitResult?.[0]?.value
              ) {
                dispatch(
                  updateProductTypeDriveCode(
                    applicationDetails?.drivePlusCodeObject?.longtypeCode,
                  ),
                );
                dispatch(
                  setPlusCodeOptionValues(
                    applicationDetails?.drivePlusCodeObject,
                  ),
                );
              } else {
                dispatch(
                  setPlusCodeOptionValues({
                    longtypeCode: inverterUnitResult?.[0]?.value,
                    options: [],
                  }),
                );
                updatePlusCode({
                  applicationId: applicationDetails?.id,
                  updatePlusCodes: {
                    longtypeCode: '',
                    options: [],
                  },
                });
                const tabs =
                  applicationDetails?.applicationActiveTabs &&
                  applicationDetails?.applicationActiveTabs?.filter(
                    (tabs) => tabs.value !== 'configurationDrive',
                  );

                dispatch(setApplicationActiveTabs(tabs));
                dispatch(setActiveTab('selection'));
                dispatch(setconfigureDriveTabStatus(false));
                dispatch(setEfficiencyTabStatus(false));
              }
              let selectionData =
                response.data?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                  ?.inverterUnit?.miscellaneousUnit?.selectionData?.[1];
              dispatch(
                driveLoadDataSave({
                  loadType:
                    response.data?.dimension?.dimensionInput
                      ?.selectionInputs?.[0]?.loadType ?? '',
                  overLoadType:
                    response.data?.dimension?.dimensionInput
                      ?.selectionInputs?.[0]?.overLoadType,
                  iContinuous:
                    response.data?.dimension?.dimensionInput
                      ?.selectionInputs?.[0]?.iContinuous ??
                    selectionData?.row?.[1],
                  dutyCycleTime:
                    response.data?.dimension?.dimensionInput
                      ?.selectionInputs?.[0]?.dutyCycleTime,
                  maxLoadTime:
                    response.data?.dimension?.dimensionInput
                      ?.selectionInputs?.[0]?.maxLoadTime,
                  iMax:
                    response.data?.dimension?.dimensionInput
                      ?.selectionInputs?.[0]?.iMax ?? selectionData?.row?.[2],
                  calculatedIContinuous: selectionData?.row?.[1],
                  calculatedIMaximum: selectionData?.row?.[2],
                  driveName:
                    response.data?.dimension?.dimensionInput
                      ?.selectionInputs?.[0]?.driveName,
                  drivesCount:
                    response.data?.dimension?.dimensionInput
                      ?.selectionInputs?.[0]?.drivesCount,
                } as DriveLoadData),
              );

              const motorError =
                response?.data?.dimension?.dimensionResult
                  ?.supplyDriveLoads?.[0]?.motorUnit?.motorError;
              const driveError =
                response?.data?.dimension?.dimensionResult
                  ?.supplyDriveLoads?.[0]?.inverterUnit?.invError;
              if (!!motorError) {
                dispatch(
                  saveMotorErrorResponse({
                    errorMotorSelection: motorError,
                    isError: motorError.toLowerCase().includes('error'),
                    isWarning: !motorError.toLowerCase().includes('error'),
                  }),
                );
              } else {
                dispatch(resetMotorErrorResponse());
              }

              if (!!driveError) {
                dispatch(
                  saveDriveErrorResponse({
                    errorDriveSelection: driveError,
                    isError: driveError.toLowerCase().includes('error'),
                    isWarning: !driveError.toLowerCase().includes('error'),
                  }),
                );
              } else {
                dispatch(resetDriveErrorResponse());
              }

              setIsGetDimensionLoading(false);
              setHasFetchedData(true);
              //add driveplsucode conditions and delete the plsucodes if the results and drive are not matching
              //if drive is undefined or if drive is not the same as the drive already saved
            } else {
              setIsLoading(false);
              setHasNoResponse(true);
              setIsGetDimensionLoading(false);
              setHasFetchedData(true);
              notificationManager.error('Unable to retrieve dimension data');
            }

            if (response.error && 'data' in response.error) {
              if (response.error.data) {
                dispatch(
                  saveMotorErrorResponse({
                    errorMotorSelection:
                      response.error?.data?.toString() ??
                      'Error fetching dimension details',
                    isError: true,
                  }),
                );
              }
            }
          })
          .catch((error) => {
            notificationManager.error(
              'Unable to retrieve dimension data',
              error.data.toString(),
            );
            setIsGetDimensionLoading(false);
            setHasFetchedData(true);
          });
      } else {
        notificationManager.error(
          t(I18.motor_selection_notification_power_non_zero_label),
        );
        setIsLoading(false);
        setIsGetDimensionLoading(false);
        setHasFetchedData(true);
      }
    }
  };

  useEffect(() => {
    if (
      Object.keys(motorParamsData)?.length > 0 &&
      updatedLoadFields?.power !== '0' &&
      workspace.fromActiveComponent === 'selection' &&
      selectionData?.power !== 0 &&
      workspace.fromActiveComponent === 'selection' &&
      !isLoading &&
      !hasNoResponse &&
      !hasFetchedData
    ) {
      setTimeout(() => {
        handleSelectButtonClick('All');
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedLoadFields, motorParamsData, hasFetchedData, driveFormData]);

  useEffect(() => {
    if (
      (!isGetDimensionLoading && isLoading && !status.isLoading) ||
      (hasTransformerReselected &&
        !motorResultData.dimension?.catalogueDataMotor) ||
      hasRetryCatalogDataSelected
    ) {
      const response = motorResultData;
      let productCode =
        response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
          ?.results?.[1]?.value;
      let typeDesignation =
        response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
          ?.results?.[0]?.value;

      let allResultData: Dimension;

      if (typeDesignation !== undefined) {
        catalogueData({
          productCode:
            productCode && productCode !== '[undefined]'
              ? productCode
              : undefined,
          typeDesignation:
            !productCode && productCode !== '[undefined]' && typeDesignation
              ? typeDesignation
              : '',
          countryCode: null,
          langCode: 'en',
        })
          .then((catalogueResponseMotor) => {
            if ('data' in catalogueResponseMotor) {
              if (
                response?.dimension?.dimensionInput?.selectionInputs?.[0]
                  ?.dataCategory === 'All'
              ) {
                let driveTypeDesignation =
                  response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                    ?.inverterUnit?.results?.[0]?.value;

                if (
                  driveTypeDesignation !== null &&
                  driveTypeDesignation !== ''
                ) {
                  catalogueData({
                    productCode: undefined,
                    typeDesignation: driveTypeDesignation ?? '',
                    countryCode: null,
                    langCode: 'en',
                  })
                    .then((catalogueResponseDrive) => {
                      if ('data' in catalogueResponseDrive) {
                        allResultData = {
                          dimension: {
                            dimensionInput: {
                              ...response?.dimension?.dimensionInput,
                            },
                            dimensionResult:
                              response?.dimension?.dimensionResult,
                            catalogueDataMotor: catalogueResponseMotor.data,
                            catalogueDataDrive: catalogueResponseDrive.data,
                          },
                          exceptionType: response?.exceptionType,
                          message: response?.message,
                        };
                        dispatch(selectDrive(allResultData));
                        dispatch(selectMotor(allResultData));
                        updateProjectApiCall(allResultData);
                        setIsLoading(false);
                      } else {
                        allResultData = {
                          dimension: {
                            dimensionInput: {
                              ...response?.dimension?.dimensionInput,
                            },
                            dimensionResult:
                              response?.dimension?.dimensionResult,
                            catalogueDataMotor: catalogueResponseMotor.data,
                          },
                          exceptionType: response?.exceptionType,
                          message: response?.message,
                        };
                        updateProjectApiCall(allResultData);
                        setIsLoading(false);
                      }
                    })
                    .catch(() => {
                      updateProjectApiCall(allResultData);
                      setIsLoading(false);
                    });
                } else {
                  allResultData = {
                    dimension: {
                      dimensionInput: {
                        ...response?.dimension?.dimensionInput,
                      },
                      dimensionResult: response?.dimension?.dimensionResult,
                      catalogueDataMotor: catalogueResponseMotor.data,
                    },
                    exceptionType: response?.exceptionType,
                    message: response?.message,
                  };
                  dispatch(selectDrive(allResultData));
                  dispatch(selectMotor(allResultData));
                  updateProjectApiCall(allResultData);
                  setIsLoading(false);
                }
              } else {
                allResultData = {
                  dimension: {
                    dimensionInput: {
                      ...response?.dimension?.dimensionInput,
                    },
                    dimensionResult: response?.dimension?.dimensionResult,
                    catalogueDataMotor: catalogueResponseMotor.data,
                  },
                  exceptionType: response?.exceptionType,
                  message: response?.message,
                };
                dispatch(selectDrive(allResultData));
                dispatch(selectMotor(allResultData));
                updateProjectApiCall(allResultData);
                setIsLoading(false);
              }
            } else {
              updateProjectApiCall(allResultData);
              setIsLoading(false);
            }
          })
          .catch(() => {
            updateProjectApiCall(allResultData);
            setIsLoading(false);
          });
      }
      dispatch(setHasRetryCatalogDataSelected(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    catalogueData,
    updatedLoadFields,
    isGetDimensionLoading,
    isLoading,
    hasRetryCatalogDataSelected,
  ]);

  const handleBackButtonClick = () => {
    setViewResult(false);
  };

  useEffect(() => {
    if (isSelectMotorClicked) {
      if (isMobile) {
        setViewResult(true);
      }
      setIsSelectMotorClicked(false);
    }
  }, [isSelectMotorClicked, isMobile]);

  const handleManualSelection = () => {
    if (motorResultData) {
      dispatch(
        dimensionMotorInputData({
          selectionInputs:
            motorResultData?.dimension?.dimensionInput?.selectionInputs,
          transformerInput: selectedTransformerDatas,
        } as DimensionInput),
      );
    }
    setViewManualSelection(true);
  };
  const onManualSelectionClose = () => {
    setViewManualSelection(false);
  };

  const getLeftSection = () => {
    let isDisabled =
      isLoading || isManualSelectionLoading || isGetDimensionLoading;
    let isRightBtnDisabled =
      !motorResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
        ?.motorUnit?.results?.[0]?.value ||
      isLoading ||
      isManualSelectionLoading ||
      isGetDimensionLoading;
    return (
      <>
        <MotorSelectionForm
          isFormLoading={isLoading}
          viewManualSelection={viewManualSelection}
          onManualSelectionClose={onManualSelectionClose}
          isManualSelectionLoading={isManualSelectionLoading}
          onManualSelectClick={handleSelectButtonClick}
        />
        {!isMobile && (
          <ButtonGroup
            isLeftBtnDisabled={isDisabled}
            isMiddleBtnDisabled={isDisabled}
            isRightBtnDisabled={isRightBtnDisabled}
            leftButtonName={t(I18.motor_selection_select_motors_button)}
            middleButtonName={t(I18.motor_selection_select_all_button)}
            rightButtonName={t(I18.motor_selection_manual_selection_button)}
            onLeftClick={
              isDisabled ? undefined : () => handleSelectButtonClick('Motor')
            }
            onMiddleClick={
              isDisabled ? undefined : () => handleSelectButtonClick('All')
            }
            onRightClick={
              isRightBtnDisabled ? undefined : () => handleManualSelection()
            }
            isSidebarOpen={sidebarOpen}
            isLoading={isLoading}
          />
        )}
      </>
    );
  };

  const getRightSection = () => {
    return (
      <MotorSelectionResult
        isLoading={isLoading}
        isError={catalogStatus.isError}
      />
    );
  };

  const mobileLayout = () => {
    return (
      <>
        {isMobile && (
          <div className="workspace-workarea">
            <DualSectionLayout
              leftChildren={getLeftSection()}
              rightChildren={getRightSection()}
              isSidebarOpen={sidebarOpen}
              viewResult={viewResult}
            />
            {isMobile && (
              <ButtonGroupMobile
                isLeftBtnDisabled={false}
                isRightBtnDisabled={false}
                leftButtonName={t(I18.motor_selection_select_button)}
                leftButtonOptions={[
                  t(I18.motor_selection_select_motors_button),
                  t(I18.motor_selection_select_all_button),
                  t(I18.motor_selection_manual_selection_button),
                ]}
                rightButtonName={t(I18.motor_selection_result_button)}
                onBackClick={handleBackButtonClick}
                onResultClick={handleSelectMotorButtonClick}
              />
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      {isMobile && mobileLayout()}
      {
        <div className="workspace-workarea">
          <DualSectionLayout
            leftChildren={getLeftSection()}
            rightChildren={getRightSection()}
            isSidebarOpen={sidebarOpen}
            viewResult={viewResult}
          />
        </div>
      }
    </div>
  );
};

export const MotorSelection = memo(MotorSelectionComponent);
